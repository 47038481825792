import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators';

import { AutosaveService } from '@nexus/services/autosave.service';
import { EnvironmentService, EnvironmentVariables } from '@nexus/core-services/environment.service';
import { ServerResponse, ServerPaginationResponse, AtomicServerResponse, SimpleServerResponse } from '@nexus/models/api.model';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient,
    private autosaveService: AutosaveService,
    private environmentService: EnvironmentService,
  ) {
    this.apiUrl = `${environmentService.get(EnvironmentVariables.API_URL)}/v1/api`;
  }

  verifyIfMpxnIsValid(payload: { mprn?: string; mpan?: string }, jobTypeId: string, appointmentTypeId: string): Observable<boolean> {
    this.autosaveService.startSaving();
    const postPayload: any = { ...payload };
    postPayload.jobType = jobTypeId;
    postPayload.appointmentType = appointmentTypeId;
    const completeApiUrl = `${this.apiUrl}/validate/mpxn/`;
    return this.httpClient
      .post<AtomicServerResponse>(completeApiUrl, postPayload, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          this.autosaveService.finishSaving();
          return true;
        }),
        catchError((err) => {
          return of(err.error?.error?.message || false);
        }),
      );
  }

  verifyIfNumberIsUkMobile(phoneNumber: string) {
    this.autosaveService.startSaving();
    const payload: any = { phoneNumber };
    const completeApiUrl = `${this.apiUrl}/validate/mobile-number-uk/`;
    return this.defaultCreate(completeApiUrl, payload);
  }

  verifyIfProfileClassIsValid(payload: { profileClass: string }) {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/validate/profile-class/`;
    return this.defaultCreate(completeApiUrl, payload);
  }

  verifyIfSscCodeIsValid(payload: { sscCode: string }) {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/validate/ssc/`;
    return this.defaultCreate(completeApiUrl, payload);
  }

  verifyIfNumberIsUkPhone(phoneNumber: string) {
    this.autosaveService.startSaving();
    const payload: any = { phoneNumber };
    const completeApiUrl = `${this.apiUrl}/validate/phone-number-uk/`;
    return this.defaultCreate(completeApiUrl, payload);
  }

  validateIfOutwardCodeIsUnique(outwardCode: string) {
    this.autosaveService.startSaving();
    const payload: any = { outwardCode };
    const completeApiUrl = `${this.apiUrl}/validate/outward-code/`;
    return this.defaultCreate(completeApiUrl, payload);
  }

  defaultCreate(completeApiUrl: string, data: any): Observable<any> {
    this.autosaveService.startSaving();
    return this.httpClient.post<AtomicServerResponse>(completeApiUrl, data, { observe: 'response' }).pipe(
      map((response) => {
        this.autosaveService.finishSaving();
        return true;
      }),
      catchError((err) => {
        return of(err.error?.error?.message || false);
      }),
    );
  }
}
