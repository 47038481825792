import { PatchModel } from './patch.model';
import { SupplierModel } from './supplier.model';
class RegionModel {
  constructor(
    public id: string,
    public code: string,
  ) {}
}

interface RegionsGroup {
  region: any;
  patches: any[];
}

interface SupplierRegionsGroup {
  supplier: any;
  region: any;
  patches: any[];
}

class RegionsGroupModel {
  constructor(
    public region: RegionModel,
    public patches: PatchModel[],
  ) {}
}

class SupplierRegionsGroupModel {
  constructor(
    public supplier: SupplierModel,
    public region: RegionModel,
    public patches: PatchModel[],
  ) {}
}

interface NewRegionData {
  code: string;
}

interface DialogData {
  operation: string;
  data: NewRegionData;
}

interface CapacityResultRegion {
  patches: any[];
  region: any;
}
interface CapacityResult {
  regions: CapacityResultRegion[];
  supplier: any;
}

export { CapacityResultRegion, CapacityResult, RegionModel, RegionsGroup, SupplierRegionsGroup, RegionsGroupModel, SupplierRegionsGroupModel, NewRegionData };
