import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  /* Unauthorized Access */
  {
    path: '',
    loadChildren: () => import('./guest/guest.module').then((m) => m.GuestModule),
  },
  /* Authorized Access */
  {
    path: 'platform',
    loadChildren: () => import('./platform/platform.module').then((m) => m.PlatformModule),
    // canLoad: [ AuthorizationGuard ]
  },
  /* Catch All */
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      initialNavigation: 'enabledNonBlocking' as const,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
