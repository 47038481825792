export const environment = {
  environment: 'STAGING',
  production: true,
  tenant: 'beboc',
  hmr: false,
  apiUrl: 'https://beboc.staging.nexus-api.reachwm.io',
  hubAuthUrl: 'https://staging.hub.reachwm.io/login?redirect=https://beboc.staging.nexus.reachwm.io',
  hubUrl: 'https://staging.hub.reachwm.io',
  directAuthUrl: 'https://staging.hub.reachwm.io/api/authenticate',
  directAuthRefreshUrl: 'https://staging.hub.reachwm.io/api/token',
  capacityTemplate: 'https://nexus-public.s3.eu-west-2.amazonaws.com/capacity-template-nexus.xlsx',
  capacityRotaTemplate: 'https://nexus-public.s3.eu-west-2.amazonaws.com/rota-template.csv',
  prebookTemplate: 'https://nexus-public.s3.eu-west-2.amazonaws.com/prebook-template.csv',
  sentry: 'https://04d045596d674d7581ec676ae7bd45a2@o122021.ingest.sentry.io/6232026',
  schedulingShowAppointmentsAfterPatch: 'false',
};
