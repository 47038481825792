import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { environment as environmentDevelop } from '../../../environments/environment.develop';
import { environment as environmentStaging } from '../../../environments/environment.staging';
import { environment as environmentStagingUwhs } from '../../../environments/environment.staging.uwhs';
import { environment as environmentStagingOctopus } from '../../../environments/environment.staging.octopus';
import { environment as environmentProduction } from '../../../environments/environment.prod';
import { environment as environmentProductionUwhs } from '../../../environments/environment.prod.uwhs';
import { environment as environmentStagingUsml } from '../../../environments/environment.staging.usml';
import { environment as environmentStagingVoltari } from '../../../environments/environment.staging.voltari';
import { environment as environmentStagingYu } from '../../../environments/environment.staging.yu';
import { environment as environmentStagingScm } from '../../../environments/environment.staging.scm';
import { environment as environmentStagingEvs } from '../../../environments/environment.staging.evs';
import { environment as environmentStagingAes } from '../../../environments/environment.staging.aes';
import { environment as environmentProductionUsml } from '../../../environments/environment.prod.usml';
import { environment as environmentProductionOctopus } from '../../../environments/environment.prod.octopus';
import { environment as environmentProductionVoltari } from '../../../environments/environment.prod.voltari';
import { environment as environmentProductionYu } from '../../../environments/environment.prod.yu';
import { environment as environmentProductionScm } from '../../../environments/environment.prod.scm';
import { environment as environmentProductionEvs } from '../../../environments/environment.prod.evs';
import { environment as environmentProductionAes } from '../../../environments/environment.prod.aes';
import { environment as environmentStagingRodgers } from '../../../environments/environment.staging.rodgers';
import { environment as environmentProductionRodgers } from '../../../environments/environment.prod.rodgers';
import { environment as environmentStagingChargedev } from '../../../environments/environment.staging.chargedev';
import { environment as environmentProductionChargedev } from '../../../environments/environment.prod.chargedev';
import { environment as environmentStagingRadius } from '../../../environments/environment.staging.radius';
import { environment as environmentProductionradius } from '../../../environments/environment.prod.radius';
import { environment as environmentStagingMpaas } from '../../../environments/environment.staging.mpaas';
import { environment as environmentProductionMpaas } from '../../../environments/environment.prod.mpaas';

enum EnvironmentVariables {
  ENVIRONMENT = 'environment',
  PRODUCTION = 'production',
  TENANT = 'tenant',
  API_URL = 'apiUrl',
  HUB_AUTH_URL = 'hubAuthUrl',
  HUB_URL = 'hubUrl',
  DIRECT_AUTH_URL = 'directAuthUrl',
  DIRECT_AUTH_REFRESH_URL = 'directAuthRefreshUrl',
  CAPACITY_TEMPLATE = 'capacityTemplate',
  CAPACITY_ROTA_TEMPLATE = 'capacityRotaTemplate',
  PREBOOK_TEMPLATE = 'prebookTemplate',
  SENTRY = 'sentry',
  SCHEDULING_SHOW_APPOINTMENTS_AFTER_PATCH = 'schedulingShowAppointmentsAfterPatch',
  DEFAULT_TODAY_CONFIG = 'DEFAULT_TODAY_CONFIG',
}

@Injectable()
export class EnvironmentService {
  public environmentConfig;

  constructor() {
    let tenant: string;
    let env: string;
    if (window.location.hostname === 'localhost') {
      tenant = 'beboc';
      env = 'staging';
    } else if (window.location.hostname === 'uwhs.nexus.reachwm.io') {
      tenant = 'uwhs';
      env = 'production';
    } else if (window.location.hostname === 'beboc.nexus.reachwm.io') {
      tenant = 'beboc';
      env = 'production';
    } else if (window.location.hostname === 'usml.nexus.reachwm.io') {
      tenant = 'usml';
      env = 'production';
    } else if (window.location.hostname === 'octopus.nexus.reachwm.io') {
      tenant = 'octopus';
      env = 'production';
    } else if (window.location.hostname === 'voltari.nexus.reachwm.io') {
      tenant = 'voltari';
      env = 'production';
    } else if (window.location.hostname === 'yu.nexus.reachwm.io') {
      tenant = 'yu';
      env = 'production';
    } else if (window.location.hostname === 'scm.nexus.reachwm.io') {
      tenant = 'scm';
      env = 'production';
    } else if (window.location.hostname === 'evs.nexus.reachwm.io') {
      tenant = 'evs';
      env = 'production';
    } else if (window.location.hostname === 'aes.nexus.reachwm.io') {
      tenant = 'aes';
      env = 'production';
    } else if (window.location.hostname === 'rodgers.nexus.reachwm.io') {
      tenant = 'rodgers';
      env = 'production';
    } else if (window.location.hostname === 'chargedev.nexus.reachwm.io') {
      tenant = 'chargedev';
      env = 'production';
    } else if (window.location.hostname === 'radius.nexus.reachwm.io') {
      tenant = 'radius';
      env = 'production';
    } else if (window.location.hostname === 'mpaas.nexus.reachwm.io') {
      tenant = 'mpaas';
      env = 'production';
    } else if (window.location.hostname === 'beboc.staging.nexus.reachwm.io') {
      tenant = 'beboc';
      env = 'staging';
    } else if (window.location.hostname === 'beboc.dev.nexus.reachwm.io') {
      tenant = 'beboc';
      env = 'dev';
    } else if (window.location.hostname === 'uwhs.staging.nexus.reachwm.io') {
      tenant = 'uwhs';
      env = 'staging';
    } else if (window.location.hostname === 'usml.staging.nexus.reachwm.io') {
      tenant = 'usml';
      env = 'staging';
    } else if (window.location.hostname === 'octopus.staging.nexus.reachwm.io') {
      tenant = 'octopus';
      env = 'staging';
    } else if (window.location.hostname === 'voltari.staging.nexus.reachwm.io') {
      tenant = 'voltari';
      env = 'staging';
    } else if (window.location.hostname === 'yu.staging.nexus.reachwm.io') {
      tenant = 'yu';
      env = 'staging';
    } else if (window.location.hostname === 'scm.staging.nexus.reachwm.io') {
      tenant = 'scm';
      env = 'staging';
    } else if (window.location.hostname === 'evs.staging.nexus.reachwm.io') {
      tenant = 'evs';
      env = 'staging';
    } else if (window.location.hostname === 'aes.staging.nexus.reachwm.io') {
      tenant = 'aes';
      env = 'staging';
    } else if (window.location.hostname === 'rodgers.staging.nexus.reachwm.io') {
      tenant = 'rodgers';
      env = 'staging';
    } else if (window.location.hostname === 'chargedev.staging.nexus.reachwm.io') {
      tenant = 'chargedev';
      env = 'staging';
    } else if (window.location.hostname === 'radius.staging.nexus.reachwm.io') {
      tenant = 'radius';
      env = 'staging';
    } else if (window.location.hostname === 'mpaas.staging.nexus.reachwm.io') {
      tenant = 'mpaas';
      env = 'staging';
    } else {
      const hostnameParts = window.location.hostname.split('.');
      tenant = hostnameParts.length === 5 ? hostnameParts[0] : undefined;
      env = hostnameParts.length === 5 ? hostnameParts[1] : hostnameParts[0];
    }
    this.loadEnvironment(env, tenant);
  }

  loadEnvironment(env: string, tenant: string) {
    if (env === 'staging' && tenant === 'uwhs') {
      this.environmentConfig = environmentStagingUwhs;
    } else if (env === 'dev' && tenant === 'beboc') {
      this.environmentConfig = environmentDevelop;
    } else if (env === 'staging' && tenant === 'beboc') {
      this.environmentConfig = environmentStaging;
    } else if (env === 'staging' && tenant === 'octopus') {
      this.environmentConfig = environmentStagingOctopus;
    } else if (env === 'staging' && tenant === 'voltari') {
      this.environmentConfig = environmentStagingVoltari;
    } else if (env === 'staging' && tenant === 'yu') {
      this.environmentConfig = environmentStagingYu;
    } else if (env === 'staging' && tenant === 'scm') {
      this.environmentConfig = environmentStagingScm;
    } else if (env === 'staging' && tenant === 'evs') {
      this.environmentConfig = environmentStagingEvs;
    } else if (env === 'staging' && tenant === 'aes') {
      this.environmentConfig = environmentStagingAes;
    } else if (env === 'staging' && tenant === 'usml') {
      this.environmentConfig = environmentStagingUsml;
    } else if (env === 'staging' && tenant === 'rodgers') {
      this.environmentConfig = environmentStagingRodgers;
    } else if (env === 'staging' && tenant === 'chargedev') {
      this.environmentConfig = environmentStagingChargedev;
    } else if (env === 'staging' && tenant === 'radius') {
      this.environmentConfig = environmentStagingRadius;
    } else if (env === 'staging' && tenant === 'mpaas') {
      this.environmentConfig = environmentStagingMpaas;
    } else if (env === 'production' && tenant === 'uwhs') {
      this.environmentConfig = environmentProductionUwhs;
    } else if (env === 'production' && tenant === 'beboc') {
      this.environmentConfig = environmentProduction;
    } else if (env === 'production' && tenant === 'usml') {
      this.environmentConfig = environmentProductionUsml;
    } else if (env === 'production' && tenant === 'octopus') {
      this.environmentConfig = environmentProductionOctopus;
    } else if (env === 'production' && tenant === 'voltari') {
      this.environmentConfig = environmentProductionVoltari;
    } else if (env === 'production' && tenant === 'yu') {
      this.environmentConfig = environmentProductionYu;
    } else if (env === 'production' && tenant === 'scm') {
      this.environmentConfig = environmentProductionScm;
    } else if (env === 'production' && tenant === 'evs') {
      this.environmentConfig = environmentProductionEvs;
    } else if (env === 'production' && tenant === 'aes') {
      this.environmentConfig = environmentProductionAes;
    } else if (env === 'production' && tenant === 'rodgers') {
      this.environmentConfig = environmentProductionRodgers;
    } else if (env === 'production' && tenant === 'chargedev') {
      this.environmentConfig = environmentProductionChargedev;
    } else if (env === 'production' && tenant === 'radius') {
      this.environmentConfig = environmentProductionradius;
    } else if (env === 'production' && tenant === 'mpaas') {
      this.environmentConfig = environmentProductionMpaas;
    } else if (env === 'production' && !tenant) {
      this.environmentConfig = environmentProduction;
    } else {
      this.environmentConfig = environment;
    }
  }

  get(environmentVariable: EnvironmentVariables) {
    return this.environmentConfig[environmentVariable];
  }
}
export { EnvironmentVariables };