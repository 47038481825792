import _ from 'lodash-es';
import { debtJourneySteps } from './appointment.model';
interface Field {
  name: string;
  prettyName?: string;
  parent;
  type;
  filters?; // deprecated
  enabledFilters?;
  values?;
  children?: [Field];
  expanded?: boolean;
  position?: number;
  disabled?: boolean;
}

interface SurveyKey {
  name: string;
  presentation?: string;
}
interface QueryBuilderField {
  field: string;
  type: string;
  label: string;
  parent?;
  operators?: any[];
  values?: string[] | number[] | number[];
  category?: string;
  template?: any;
  enabledFilters?;
  children?: [Field];
  expanded?: boolean;
  position?: number;
  disabled?: boolean;
}

enum ReportType {
  APPOINTMENTS = 'APPOINTMENTS',
  CAPACITY = 'CAPACITY',
  AVAILABILITY = 'AVAILABILITY',
}

interface DialogData {
  operation: string;
  data: NewReportData;
}

interface AutomationDialogData {
  operation: string;
  data: Automation;
}

interface NewReportData {
  name: string;
  visibility: string;
  frequency?: string[];
}

interface TimeWindow {
  from: string;
  to: string;
}

interface ActiveFilter {
  type: string;
  enabledFilters: any;
  name: string;
}

interface Filter {
  column;
  filter;
  type;
}

interface Report {
  name: string;
  visibility: string;
  columns: string[];
  type: ReportType;
  timeWindow?: TimeWindow;
  id?: string;
  user?: any;
  queryBuilderSqlFilters: FiltersQueryBuilder;
}

interface FiltersQueryBuilder {
  rulesSql?: string;
  rulesJson?: any;
  date: any;
}

interface ReportUpdate {
  name?: string;
  visibility?: string;
  filters?: Filter[];
  columns?: string[];
  timeWindow?: TimeWindow;
  id?: string;
  user?: any;
}

class ReportModel {
  constructor(
    public id: string,
    public name: string,
    public visibility: string,
    public filters: Filter[],
    public columns: string[],
    public timeWindow: TimeWindow,
    public user?: any,
    public queryBuilderSqlFilters?: FiltersQueryBuilder,
  ) {}
}

enum Frequency {
  MANUAL = 'Preview',
  AUTOMATED = 'Automated',
}

const databaseFiltersNumbers = [
  { name: 'Equals', operator: 'equal' },
  { name: 'Not equal', operator: 'notequal' },
  { name: 'Less than', operator: 'lt' },
  { name: 'Less than or equals', operator: 'lte' },
  { name: 'Greater than', operator: 'gt' },
  { name: 'Greater than or equals', operator: 'gte' },
  { name: 'In range', operator: 'range' },
  { name: 'Is Set', operator: 'isset' },
  { name: 'Is Not Set', operator: 'isnotset' },
];
const enumOperators = [
  { value: 'equal', key: 'Equal' },
  { value: 'notequal', key: 'Not Equal' },
];

const enumSearchOperators = [
  { value: 'equal', key: 'Equal' },
  { value: 'notequal', key: 'Not Equal' },
];

const databaseFiltersStrings = [
  { name: 'Contains', operator: 'contains' },
  { name: 'Equal', operator: 'equal' },
  { name: 'Not equal', operator: 'notequal' },
  { name: 'Is Set', operator: 'isset' },
  { name: 'Is Not Set', operator: 'isnotset' },
];

const databaseFiltersAppointmentTypes = [
  { name: 'SITE_SURVEY', operator: 'enum' },
  { name: 'WARRANT', operator: 'enum' },
  { name: 'SCHEDULED_EXCHANGE', operator: 'enum' },
  { name: 'EMERGENCY_EXCHANGE', operator: 'enum' },
  { name: 'DEEMED_EXCHANGE', operator: 'enum' },
  { name: 'RECOMMISSION', operator: 'enum' },
  { name: 'POWER_CYCLE', operator: 'enum' },
  { name: 'NEW_CONNECTION', operator: 'enum' },
  { name: 'METER_REMOVAL', operator: 'enum' },
  { name: 'EMERGENCY_INVESTIGATION', operator: 'enum' },
  { name: 'SCHEDULED_INVESTIGATION', operator: 'enum' },
];

const databaseFiltersJobTypes = [
  { name: 'ELECTRIC', operator: 'enum' },
  { name: 'GAS', operator: 'enum' },
  { name: 'DUAL_FUEL', operator: 'enum' },
  { name: 'TRADITIONAL_GAS', operator: 'enum' },
  { name: 'TRADITIONAL_ELECTRIC', operator: 'enum' },
  { name: 'ELECTRIC_SMETS_2', operator: 'enum' },
  { name: 'GAS_SMETS_2', operator: 'enum' },
  { name: 'DUAL_FUEL_SMETS_2', operator: 'enum' },
  { name: 'SITE_INVESTIGATION', operator: 'enum' },
  { name: 'GIST', operator: 'enum' },
  { name: 'SCHEDULED_POWER_CYCLE', operator: 'enum' },
  { name: 'DEEMED_POWER_CYCLE', operator: 'enum' },
];

const databaseFiltersStatus = [
  { name: 'COMPLETED', operator: 'enum' },
  { name: 'STARTED', operator: 'enum' },
  { name: 'ON_SITE', operator: 'enum' },
  { name: 'ON_ROUTE', operator: 'enum' },
  { name: 'CANCELLED', operator: 'enum' },
  { name: 'ABORTED', operator: 'enum' },
  { name: 'BOOKED', operator: 'enum' },
  { name: 'PAUSED', operator: 'enum' },
];

const dateRangeFilters = [
  { name: 'from', operator: 'date' },
  { name: 'to', operator: 'date' },
];

const databaseFiltersAccountTypes = [
  { name: 'credit', operator: 'enum' },
  { name: 'prepay', operator: 'enum' },
];

const capacityFields: Field[] = [
  {
    name: 'date',
    parent: null,
    type: 'DateRange',
    filters: dateRangeFilters,
  },
  {
    name: 'supplier',
    parent: null,
    type: 'String',
    filters: enumOperators,
    values: [],
  },
  {
    name: 'region',
    parent: null,
    type: 'String',
    filters: databaseFiltersStrings,
  },
  {
    name: 'patch',
    parent: null,
    type: 'String',
    filters: databaseFiltersStrings,
  },
  {
    name: 'patchName',
    parent: null,
    type: 'String',
    filters: databaseFiltersStrings,
  },
  {
    name: 'capacityTimeSlot86',
    parent: null,
    type: 'String',
    filters: databaseFiltersStrings,
    disabled: true,
  },
  {
    name: 'capacityTimeSlot812',
    parent: null,
    type: 'String',
    filters: databaseFiltersStrings,
    disabled: true,
  },
  {
    name: 'capacityTimeSlot15',
    parent: null,
    type: 'String',
    filters: databaseFiltersStrings,
    disabled: true,
  },
  {
    name: 'capacityTimeSlot810',
    parent: null,
    type: 'String',
    filters: databaseFiltersStrings,
    disabled: true,
  },
  {
    name: 'capacityTimeSlot1012',
    parent: null,
    type: 'String',
    filters: databaseFiltersStrings,
    disabled: true,
  },
  {
    name: 'capacityTimeSlot122',
    parent: null,
    type: 'String',
    filters: databaseFiltersStrings,
    disabled: true,
  },
  {
    name: 'capacityTimeSlot24',
    parent: null,
    type: 'String',
    filters: databaseFiltersStrings,
    disabled: true,
  },
  {
    name: 'capacityTimeSlot46',
    parent: null,
    type: 'String',
    filters: databaseFiltersStrings,
    disabled: true,
  },
  {
    name: 'capacityTimeSlot68',
    parent: null,
    type: 'String',
    filters: databaseFiltersStrings,
    disabled: true,
  },
  // 3 new timeSlots
  {
    name: 'capacityTimeSlot068',
    parent: null,
    type: 'String',
    filters: databaseFiltersStrings,
    disabled: true,
  },
  {
    name: 'capacityTimeSlot58',
    parent: null,
    type: 'String',
    filters: databaseFiltersStrings,
    disabled: true,
  },
  {
    name: 'capacityTimeSlot8100',
    parent: null,
    type: 'String',
    filters: databaseFiltersStrings,
    disabled: true,
  },
  {
    name: 'capacityAllTimeSlots',
    parent: null,
    type: 'String',
    filters: databaseFiltersStrings,
    disabled: true,
  },
];

// TODO: Transform this to dynamic (from DB) - This for Fields
const supportedCapacityFieldsMap: Map<string, any> = new Map<string, any>([
  ['date', { modelPath: 'day', presentation: 'Date', type: 'Date' }],
  ['supplier', { modelPath: 'supplier.name', presentation: 'Supplier', type: 'String' }],
  ['region', { modelPath: 'region.code', presentation: 'Region', type: 'String' }],
  ['patch', { modelPath: 'patch.code', presentation: 'Patch Code', type: 'String' }],
  ['patchName', { modelPath: 'patch.name', presentation: 'Patch Name', type: 'String' }],
  // ['slot', { modelPath: 'timeSlot.name', presentation: 'Time Slot', type: 'String' }],
  ['capacityTimeSlot86', { modelPath: 'capacityTimeSlot86', presentation: '8am - 6pm', type: 'String' }],
  ['capacityTimeSlot812', { modelPath: 'capacityTimeSlot812', presentation: '8am - 12pm', type: 'String' }],
  ['capacityTimeSlot15', { modelPath: 'capacityTimeSlot15', presentation: '1pm - 5pm', type: 'String' }],
  ['capacityTimeSlot810', { modelPath: 'capacityTimeSlot810', presentation: '8am - 10am', type: 'String' }],
  ['capacityTimeSlot1012', { modelPath: 'capacityTimeSlot1012', presentation: '10am - 12pm', type: 'String' }],
  ['capacityTimeSlot122', { modelPath: 'capacityTimeSlot122', presentation: '12pm - 2pm', type: 'String' }],
  ['capacityTimeSlot24', { modelPath: 'capacityTimeSlot24', presentation: '2pm - 4pm', type: 'String' }],
  ['capacityTimeSlot46', { modelPath: 'capacityTimeSlot46', presentation: '4pm - 6pm', type: 'String' }],
  ['capacityTimeSlot68', { modelPath: 'capacityTimeSlot68', presentation: '6pm - 8pm', type: 'String' }],
  ['capacityTimeSlot8100', { modelPath: 'capacityTimeSlot8100', presentation: '8pm - 10pm', type: 'String' }],
  ['capacityTimeSlot068', { modelPath: 'capacityTimeSlot068', presentation: '6am - 8am', type: 'String' }],
  ['capacityTimeSlot58', { modelPath: 'capacityTimeSlot58', presentation: '5pm - 8pm', type: 'String' }],
  ['capacityAllTimeSlots', { modelPath: 'capacityAllTimeSlots', presentation: 'Total', type: 'String' }],
]);

// Columns for the Appointments Table
const hhFields: Field[] = [
  {
    name: 'hhMeters0Serial',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhMeters0Status_id',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhMeters0Current_rating',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhMeters0Ssc',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhMeters0Vt_ratio',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhMeters0Ct_ratio',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhMeters0Registers0Register_id',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhMeters0Registers0Time_pattern_regime_code',
    parent: 'HH',
    type: 'Number'
  },
  {
    name: 'hhMeters0Registers0Outstation_id',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhMeters0Registers1Register_id',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhMeters0Registers1Time_pattern_regime_code',
    parent: 'HH',
    type: 'Number'
  },
  {
    name: 'hhMeters0Registers1Outstation_id',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhMeters0Registers2Register_id',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhMeters0Registers2Time_pattern_regime_code',
    parent: 'HH',
    type: 'Number'
  },
  {
    name: 'hhMeters0Registers2Outstation_id',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhMeters0Registers3Register_id',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhMeters0Registers3Time_pattern_regime_code',
    parent: 'HH',
    type: 'Number'
  },
  {
    name: 'hhMeters0Registers3Outstation_id',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhMeters0Registers4Register_id',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhMeters0Registers4Time_pattern_regime_code',
    parent: 'HH',
    type: 'Number'
  },
  {
    name: 'hhMeters0Registers4Outstation_id',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhMeters0Registers5Register_id',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhMeters0Registers5Time_pattern_regime_code',
    parent: 'HH',
    type: 'Number'
  },
  {
    name: 'hhMeters0Registers5Outstation_id',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhMeterpointComplex_site_indicator',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhMeterpointNumber_of_feeders',
    parent: 'HH',
    type: 'Number'
  },
  {
    name: 'hhOutstations0Outstation_id',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhOutstations0Outstation_type',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhOutstations0Outstation_number_of_channels',
    parent: 'HH',
    type: 'Number'
  },
  {
    name: 'hhOutstations0Outstation_number_of_dials',
    parent: 'HH',
    type: 'Number'
  },
  {
    name: 'hhOutstations0Outstation_pin',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhOutstations0Outstation_username_level_1',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhOutstations0Outstation_password_level_1',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhOutstations0Outstation_username_level_2',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhOutstations0Outstation_password_level_2',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhOutstations0Outstation_username_level_3',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhOutstations0Outstation_password_level_3',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhOutstations0.modem_type',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhOutstations0.reader_password',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhOutstations0Communication_details0Communication_method',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhOutstations0Communication_details0Communications_address',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhOutstations0Communication_details0Dial_in_out_indicator',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhOutstations0Communication_details0Baud_rate',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhOutstations0Communication_details0Communications_provider',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhOutstations0Communication_details0Communication_method_b',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhOutstations0Communication_details0Communications_address_b',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhOutstations0Communication_details0Sim_serial_number',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhOutstations0Communication_details0Sequence_mpan_core',
    parent: 'HH',
    type: 'Number'
  },
  {
    name: 'hhOutstations0Communication_details0Sequence_outstation_id',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhFeeders0Feeder_id',
    parent: 'HH',
    type: 'Number'
  },
  {
    name: 'hhFeeders0Feeder_status',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhFeeders0Number_of_phases',
    parent: 'HH',
    type: 'Number'
  },
  {
    name: 'hhFeeders1Feeder_id',
    parent: 'HH',
    type: 'Number'
  },
  {
    name: 'hhFeeders1Feeder_status',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhFeeders1Number_of_phases',
    parent: 'HH',
    type: 'Number'
  },
  {
    name: 'hhFeeders2Feeder_id',
    parent: 'HH',
    type: 'Number'
  },
  {
    name: 'hhFeeders2Feeder_status',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhFeeders2Number_of_phases',
    parent: 'HH',
    type: 'Number'
  },
  {
    name: 'hhProfileClass',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhIsRelatedMPAN',
    parent: 'HH',
    type: 'String'
  },
  {
    name: 'hhRelatedMPANNumber',
    parent: 'HH',
    type: 'String'
  }
];
const genericFields: Field[] = [
  {
    name: 'cloned',
    parent: null,
    type: 'String',
  },
  {
    name: 'inFlightJobTypeChange',
    parent: null,
    type: 'String',
  },
  {
    name: 'inFlightJobTypeChangeBy',
    parent: null,
    type: 'String',
  },
  {
    name: 'inFlightJobTypeChangeDate',
    parent: null,
    type: 'String',
  },
  {
    name: 'inFlightJobTypeChangeReason',
    parent: null,
    type: 'String',
  },
  {
    name: 'inFlightJobTypeChangeSubReason',
    parent: null,
    type: 'String',
  },
  {
    name: 'multiEngineerJob',
    parent: null,
    type: 'String',
  },
  {
    name: 'surveysReceived',
    parent: null,
    type: 'String',
  },
  {
    name: 'isVip',
    parent: null,
    type: 'String',
  },
  {
    name: 'nonChargeable',
    parent: null,
    type: 'String',
  },
  {
    name: 'isFlexible',
    parent: null,
    type: 'String',
  },
  {
    name: 'lastEditedBy',
    parent: null,
    type: 'String',
  },
  {
    name: 'journeyCommsEmailBounce',
    parent: null,
    type: 'String',
  },
  {
    name: 'journeyCommsEmailFail',
    parent: null,
    type: 'String',
  },
  {
    name: 'journeyCommsEmailComplaint',
    parent: null,
    type: 'String',
  },
  {
    name: 'journeyCommsEmailStatus',
    parent: null,
    type: 'String',
  },
  {
    name: 'journeyCommsEmailStatusChangeDate',
    parent: null,
    type: 'String',
  },
  {
    name: 'bookingMethod',
    parent: null,
    type: 'String',
  },
  {
    name: 'rescheduledFromTimeSlot',
    parent: null,
    type: 'String',
  },
  {
    name: 'dcc',
    parent: null,
    type: 'String',
  },
  {
    name: 'hse',
    parent: null,
    type: 'String',
  },
  {
    name: 'vulnerable',
    parent: null,
    type: 'String',
  },
  {
    name: 'ssc',
    parent: null,
    type: 'String',
  },
  {
    name: 'ukProfileClass',
    parent: null,
    type: 'String',
  },
  {
    name: 'customerFirstName',
    parent: null,
    type: 'String',
  },
  {
    name: 'customerSurname',
    parent: null,
    type: 'String',
  },
  {
    name: 'customerTitle',
    parent: null,
    type: 'String',
  },
  {
    name: 'contactEmail',
    parent: null,
    type: 'String',
  },
  {
    name: 'contactNumber',
    parent: null,
    type: 'String',
  },
  {
    name: 'contactName',
    parent: null,
    type: 'String',
  },
  {
    name: 'patchName',
    parent: null,
    type: 'String',
  },
  {
    name: 'isRescheduled',
    parent: null,
    type: 'String',
  },
  {
    name: 'gsosYesNo',
    parent: null,
    type: 'String',
  },
  {
    name: 'customerConfirmation',
    parent: null,
    type: 'String',
  },
  {
    name: 'bookedByName',
    parent: null,
    type: 'String',
  },
  {
    name: 'cancelledBy',
    parent: null,
    type: 'String',
  },
  {
    name: 'rescheduledBy',
    parent: null,
    type: 'String',
  },
  {
    name: 'rescheduledAt',
    parent: null,
    type: 'String',
  },
  {
    name: 'rescheduledFrom',
    parent: null,
    type: 'String',
  },
  {
    name: 'rescheduledTo',
    parent: null,
    type: 'String',
  },
  {
    name: 'comments',
    parent: null,
    type: 'String',
  },
  {
    name: 'rescheduledToTimeSlot',
    parent: null,
    type: 'String',
  },
  {
    name: 'rescheduledFromTimeSlot',
    parent: null,
    type: 'String',
  },
  {
    name: 'closedAt',
    parent: null,
    type: 'String',
  },
  {
    name: 'appointmentDate',
    parent: null,
    type: 'DateRange',
  },
  {
    name: 'patch',
    parent: null,
    type: 'String',
  },
  {
    name: 'appointmentType',
    parent: null,
    type: 'Enum',
  },
  {
    name: 'cancellationReason',
    parent: null,
    type: 'String',
    disabled: true,
  },
  {
    name: 'cancellationSubReason',
    parent: null,
    type: 'String',
    disabled: true,
  },
  {
    name: 'cancellationReasonCode',
    parent: null,
    type: 'Enum',
    disabled: true,
  },
  {
    name: 'cancellationComment',
    parent: null,
    type: 'String',
    disabled: true,
  },
  {
    name: 'bookingReference',
    parent: null,
    type: 'String',
  },
  {
    name: 'accountNumber',
    parent: null,
    type: 'String',
  },
  {
    name: 'customerAdditionalReference',
    parent: null,
    type: 'String',
  },
  {
    name: 'customerPartnerDescription',
    parent: null,
    type: 'String',
  },
  {
    name: 'customerNumber',
    parent: null,
    type: 'String',
  },
  {
    name: 'customerVulnerabilities',
    parent: null,
    type: 'String',
    disabled: true,
  },
  {
    name: 'accountType',
    parent: null,
    type: 'Enum',
  },
  {
    name: 'engineer',
    parent: null,
    type: 'String',
    values: [],
  },
  {
    name: 'mpan',
    parent: null,
    type: 'String',
  },
  {
    name: 'mprn',
    parent: null,
    type: 'String',
  },
  {
    name: 'jobType',
    parent: null,
    type: 'Enum',
  },
  {
    name: 'fuelType',
    parent: null,
    type: 'Enum',
  },
  {
    name: 'outwardCode',
    parent: null,
    type: 'String',
  },
  {
    name: 'slot',
    parent: null,
    type: 'Enum',
    filters: [], // not being used
  },
  {
    name: 'bookedBy',
    parent: null,
    type: 'String',
  },
  {
    name: 'supplier',
    parent: null,
    type: 'String',
  },
  {
    name: 'region',
    parent: null,
    type: 'String',
    disabled: true,
  },
  {
    name: 'status',
    parent: null,
    type: 'Enum',
  },
  {
    name: 'priority',
    parent: null,
    type: 'Enum',
  },
  {
    name: 'wasSlotChanged',
    parent: null,
    type: 'String',
    disabled: true,
  },
  {
    name: 'timeOnSite',
    parent: null,
    type: 'String',
    disabled: true,
  },
  {
    name: 'wanStatus',
    parent: null,
    type: 'String',
    disabled: true,
  },
  {
    name: 'abortedAt',
    parent: null,
    type: 'Date',
    disabled: true,
  },
  {
    name: 'noop',
    parent: null,
    type: 'String',
    disabled: true,
  },
  {
    name: 'bookedAt',
    parent: null,
    type: 'Date',
    disabled: true,
  },
  {
    name: 'cancelledAt',
    parent: null,
    type: 'Date',
    disabled: true,
  },
  {
    name: 'completedAt',
    parent: null,
    type: 'Date',
    disabled: true,
  },
  {
    name: 'startedAt',
    parent: null,
    type: 'Date',
    disabled: true,
  },
  {
    name: 'onSiteAt',
    parent: null,
    type: 'Date',
    disabled: true,
  },
  {
    name: 'onRouteAt',
    parent: null,
    type: 'Date',
    disabled: true,
  },
  {
    name: 'lastEventAt',
    parent: null,
    type: 'Date',
    disabled: true,
  },
  {
    name: 'dataConsent',
    parent: null,
    type: 'Enum',
    disabled: true,
  },
  {
    name: 'address1',
    parent: null,
    type: 'String',
    disabled: true,
  },
  {
    name: 'address2',
    parent: null,
    type: 'String',
    disabled: true,
  },
  {
    name: 'address3',
    parent: null,
    type: 'String',
    disabled: true,
  },
  {
    name: 'address4',
    parent: null,
    type: 'String',
    disabled: true,
  },
  {
    name: 'county',
    parent: null,
    type: 'String',
    disabled: true,
  },
  {
    name: 'city',
    parent: null,
    type: 'String',
    disabled: true,
  },
  {
    name: 'meterSerialNumberElectric',
    parent: null,
    type: 'String',
    disabled: true,
  },
  {
    name: 'meterSerialNumberGas',
    parent: null,
    type: 'String',
    disabled: true,
  },
  {
    name: 'debtCurrentDebtValue',
    parent: 'Debt',
    type: 'String',
    disabled: true,
  },
  {
    name: 'debtDateOfBill',
    parent: 'Debt',
    type: 'String',
    disabled: true,
  },
  {
    name: 'debtDateOfRedReminder',
    parent: 'Debt',
    type: 'String',
    disabled: true,
  },
  {
    name: 'debtMinDebtAcceptedByClient',
    parent: 'Debt',
    type: 'String',
    disabled: true,
  },
  {
    name: 'debtReferralForDebtCollectionActivity',
    parent: 'Debt',
    type: 'String',
    disabled: true,
  },
  {
    name: 'debtPreDisconnectionVisit',
    parent: 'Debt',
    type: 'String',
    disabled: true,
  },
  {
    name: 'debtIntendedDateForExecution',
    parent: 'Debt',
    type: 'String',
    disabled: true,
  },
  {
    name: 'debtDebtJourney',
    parent: 'Debt',
    type: 'String',
    disabled: true,
  },
  {
    name: 'debtNextActionDate',
    parent: 'Debt',
    type: 'String',
    disabled: true,
  },
  {
    name: 'wanAdditionalInformation',
    parent: 'Wan',
    type: 'String',
    disabled: true,
  },
  {
    name: 'wanAnticipatedCoverageDate',
    parent: 'Wan',
    type: 'Date',
    disabled: true,
  },
  {
    name: 'wanAuxiliaryEquipmentRequired',
    parent: 'Wan',
    type: 'String',
    disabled: true,
  },
  {
    name: 'wanConnectivityLikelihood',
    parent: 'Wan',
    type: 'String',
    disabled: true,
  },
  {
    name: 'wanCoverageAvailability',
    parent: 'Wan',
    type: 'String',
    disabled: true,
  },
  {
    name: 'wanCspRegion',
    parent: 'Wan',
    type: 'String',
    disabled: true,
  },
  {
    name: 'wanStatusCheckedAt',
    parent: 'Wan',
    type: 'String',
    disabled: true,
  },
  {
    name: 'wanTechnology',
    parent: 'Wan',
    type: 'String',
    disabled: true,
  },
  {
    name: 'is_workflow_email_sending_enabled',
    parent: null,
    type: 'String',
  },
  {
    name: 'is_workflow_sms_sending_enabled',
    parent: null,
    type: 'String',
  },
  {
    name: 'is_workflow_white_mail_sending_enabled',
    parent: null,
    type: 'String',
  },
  {
    name: 'supply_start_date',
    parent: null,
    type: 'Date',
  },
  {
    name: 'bookedByUserGroup',
    parent: null,
    type: 'String',
  },
  {
    name: 'contactSms',
    parent: null,
    type: 'String',
  },
  {
    name: 'isScheduled',
    parent: null,
    type: 'String',
  },
  {
    name: 'preferredContact',
    parent: null,
    type: 'String',
  },
  {
    name: 'comms_history',
    parent: null,
    type: 'String',
  },
  {
    name: 'last_comms_sent',
    parent: null,
    type: 'String',
  },
  {
    name: 'mediumPressure',
    parent: null,
    type: 'String',
  },
  {
    name: 'threePhase',
    parent: null,
    type: 'String',
  },
  {
    name: 'economySeven',
    parent: null,
    type: 'String',
  },
  {
    name: 'currentTransformer',
    parent: null,
    type: 'String',
  },
  {
    name: 'overBooked',
    parent: null,
    type: 'String',
  },
  {
    name: 'lastAssigner',
    parent: null,
    type: 'String',
  },
];
const fields = [...genericFields, ...hhFields];

/** @type {*}
 * @deprecated Refrain from using this mapping. It is not guaranteed to be up to date. Use from API
 */
const supportedReportFieldsMap: Map<string, any> = new Map<string, any>([
  ['customerConfirmation', { modelPath: 'events.processed', presentation: 'Customer Confirmed', type: 'String' }],
  ['inFlightJobTypeChange', { modelPath: 'events.processed', presentation: 'Job Type Change', type: 'String' }],
  ['inFlightJobTypeChangeBy', { modelPath: 'events.processed', presentation: 'Job Type Change By', type: 'String' }],
  ['inFlightJobTypeChangeDate', { modelPath: 'events.processed', presentation: 'Job Type Change Date', type: 'Date' }],
  ['inFlightJobTypeChangeReason', { modelPath: 'events.processed', presentation: 'Job Type Change Reason', type: 'String' }],
  ['inFlightJobTypeChangeSubReason', { modelPath: 'events.processed', presentation: 'Job Type Change SubReason', type: 'String' }],
  ['hse', { modelPath: 'hse', presentation: 'HSE', type: 'String' }],
  ['vulnerable', { modelPath: 'vulnerable', presentation: 'Vulnerable', type: 'String' }],
  ['surveysReceived', { modelPath: 'events.processed', presentation: '', type: 'String' }],
  ['isVip', { modelPath: 'customer.vip', presentation: '', type: 'String' }],
  ['multiEngineerJob', { modelPath: 'events.processed', presentation: '', type: 'String' }],
  ['isFlexible', { modelPath: 'customer.flexible', presentation: '', type: 'String' }],
  ['bookingMethod', { modelPath: 'bookingMethod', presentation: 'Booking Method', type: 'String' }],
  ['journeyCommsEmailStatusChangeDate', { modelPath: 'events.processed', presentation: 'Latest Journey Email Status Change Date', type: 'Date' }],
  ['journeyCommsEmailStatus', { modelPath: 'events.processed', presentation: 'Latest Journey Email Status', type: 'String' }],
  ['journeyCommsEmailFail', { modelPath: 'events.processed', presentation: 'Has Journey Email Fail', type: 'String' }],
  ['journeyCommsEmailComplaint', { modelPath: 'events.processed', presentation: 'Has Journey Email Received Complaint', type: 'String' }],
  ['journeyCommsEmailBounce', { modelPath: 'events.processed', presentation: 'Has Journey Email Bounced', type: 'String' }],
  ['lastEditedBy', { modelPath: 'lastEditedBy.name', presentation: 'Last Edited By', type: 'String' }],
  ['ssc', { modelPath: 'meterDetails.electric.sscCode', presentation: 'SSC', type: 'String' }],
  ['ukProfileClass', { modelPath: 'meterDetails.electric.profileClass', presentation: 'UK Profile Class', type: 'String' }],
  ['customerFirstName', { modelPath: 'customer.firstName', presentation: 'Customer first name', type: 'String' }],
  ['customerAdditionalReference', { modelPath: 'customer.additionalReference', presentation: 'Customer Additional Reference', type: 'String' }],
  ['customerPartnerDescription', { modelPath: 'customer.partnerDescription', presentation: 'Customer Partner Description', type: 'String' }],
  ['customerSurname', { modelPath: 'customer.surname', presentation: 'Customer surname', type: 'String' }],
  ['customerTitle', { modelPath: 'customer.title', presentation: 'Customer title', type: 'String' }],
  ['contactEmail', { modelPath: 'contact.contactEmail', presentation: 'Contact email address', type: 'String' }],
  ['contactNumber', { modelPath: 'contact.contactTelephone', presentation: 'Contact number', type: 'String' }],
  ['contactSms', { modelPath: 'contact.contactSms', presentation: 'Contact SMS', type: 'String' }],
  ['contactName', { modelPath: 'events.processed', presentation: 'Contact name', type: 'String' }],
  ['patchName', { modelPath: 'slot.patch.name', presentation: 'Patch name', type: 'String' }],
  ['isRescheduled', { modelPath: 'events.processed', presentation: 'Is rescheduled', type: 'String' }],
  ['gsosYesNo', { modelPath: 'events.processed', presentation: 'GSOS yes/no', type: 'String' }], //  (if there is a Customer Agreement survey present yes else no)
  ['lastAssigner', { modelPath: 'events.processed', presentation: 'Assigner', type: 'String' }],
  ['bookedByName', { modelPath: 'editedBy.name', presentation: 'Booked by name', type: 'String' }],
  ['cancelledBy', { modelPath: 'events.processed', presentation: 'Cancelled by', type: 'String' }], // (if the appointment is cancelled show user)
  ['rescheduledBy', { modelPath: 'events.processed', presentation: 'Rescheduled by', type: 'String' }],
  ['rescheduledAt', { modelPath: 'events.processed', presentation: 'Rescheduled at', type: 'Date' }],
  ['rescheduledFrom', { modelPath: 'events.processed', presentation: 'Rescheduled from', type: 'Date' }],
  ['rescheduledTo', { modelPath: 'events.processed', presentation: 'Rescheduled to', type: 'Date' }],
  ['rescheduledToTimeSlot', { modelPath: 'events.processed', presentation: 'Rescheduled to timeslot', type: 'String' }],
  ['rescheduledFromTimeSlot', { modelPath: 'events.processed', presentation: 'Rescheduled from timeslot', type: 'String' }],
  ['closedAt', { modelPath: 'events.processed', presentation: 'Closed At', type: 'Date' }],
  ['comments', { modelPath: 'events.processed', presentation: 'Comments', type: 'String' }],
  // Old pack
  ['wasSlotChanged', { modelPath: 'events.processed', presentation: 'Previous Slot', type: 'String' }],
  ['timeOnSite', { modelPath: 'events.processed', presentation: 'Time On Site', type: 'Number' }],
  // Debt
  ['debtCurrentDebtValue', { modelPath: 'debt.currentDebtValue', presentation: 'Current Debt Value', type: 'String' }],
  ['debtDateOfBill', { modelPath: 'debt.dateOfBill', presentation: 'Date Of Bill', type: 'String' }],
  ['debtDateOfRedReminder', { modelPath: 'debt.dateOfRedReminder', presentation: 'Date of Red Reminder', type: 'String' }],
  ['debtMinDebtAcceptedByClient', { modelPath: 'debt.minimumDebtAcceptedByClient', presentation: 'Min Debt Accepted By Client', type: 'String' }],
  [
    'debtReferralForDebtCollectionActivity',
    { modelPath: 'debt.referralForDebtCollectionActivity', presentation: 'Referral For Debt Collection Activity', type: 'String' },
  ],
  ['debtPreDisconnectionVisit', { modelPath: 'debt.preDisconnectionVisit', presentation: 'Pre Disconnection Visit', type: 'String' }],
  ['debtIntendedDateForExecution', { modelPath: 'debt.intendedDateForExecution', presentation: 'Intended Date For Execution', type: 'String' }],
  ['debtDebtJourney', { modelPath: 'debt.debtJourneyActiveStep', presentation: 'Current Debt Journey', type: 'String' }],
  ['debtNextActionDate', { modelPath: 'debt.debtJourneyDate', presentation: 'Current Debt Journey Next Action Date', type: 'String' }],
  // Wan
  ['wanStatus', { modelPath: 'wan.status', presentation: 'Wan Status', type: 'String' }],
  [
    'wanAdditionalInformation',
    {
      modelPath: 'wan.additionalInformation',
      presentation: 'WAN Additional Information',
      type: 'String',
    },
  ],
  [
    'wanAnticipatedCoverageDate',
    {
      modelPath: 'wan.anticipatedCoverageDate',
      presentation: 'WAN Anticipated Coverage Date',
      type: 'Date',
    },
  ],
  [
    'wanAuxiliaryEquipmentRequired',
    {
      modelPath: 'wan.auxiliaryEquipmentRequired',
      presentation: 'WAN Auxiliary Equipment Required',
      type: 'String',
    },
  ],
  [
    'wanConnectivityLikelihood',
    {
      modelPath: 'wan.connectivityLikelihood',
      presentation: 'WAN Connectivity Likelihood',
      type: 'String',
    },
  ],
  [
    'wanCoverageAvailability',
    {
      modelPath: 'wan.coverageAvailability',
      presentation: 'WAN Coverage Availability',
      type: 'String',
    },
  ],
  ['wanCspRegion', { modelPath: 'wan.cspRegion', presentation: 'WAN CSP Region', type: 'String' }],
  ['wanStatusCheckedAt', { modelPath: 'wan.updatedOn', presentation: 'WAN Status Checked At', type: 'Date' }],
  ['wanTechnology', { modelPath: 'wan.wanTechnology', presentation: 'WAN Technology', type: 'String' }],
  ['dataConsent', { modelPath: 'site.dataConsent', presentation: 'Data Consent', type: 'String' }],
  ['address1', { modelPath: 'site.address1', presentation: 'Address Line 1', type: 'String' }],
  ['address2', { modelPath: 'site.address2', presentation: 'Address Line 2', type: 'String' }],
  ['address3', { modelPath: 'site.address3', presentation: 'Address Line 3', type: 'String' }],
  ['address4', { modelPath: 'site.address4', presentation: 'Address Line 4', type: 'String' }],
  ['city', { modelPath: 'site.city', presentation: 'Address City', type: 'String' }],
  ['county', { modelPath: 'site.county', presentation: 'Address County', type: 'String' }],
  ['cancellationReason', { modelPath: 'cancellation.reason', presentation: 'Cancelled Reason', type: 'String' }],
  ['cancellationSubReason', { modelPath: 'cancellation.subReason', presentation: 'Cancelled Subreason', type: 'String' }],
  ['cancellationReasonCode', { modelPath: 'cancellation.subReasonCode', presentation: 'Cancelled Code', type: 'String' }],
  ['cancellationComment', { modelPath: 'cancellation.comments', presentation: 'Cancelled Comments', type: 'String' }],
  ['cancelledAt', { modelPath: 'events.processed', presentation: 'Cancelled At', type: 'Date' }],
  ['bookedAt', { modelPath: 'events.processed', presentation: 'Booked On', type: 'Date' }],
  ['abortedAt', { modelPath: 'events.processed', presentation: 'Aborted At X', type: 'Date' }],
  ['completedAt', { modelPath: 'events.processed', presentation: 'Completed At', type: 'Date' }],
  ['startedAt', { modelPath: 'events.processed', presentation: 'Started At', type: 'Date' }],
  ['onSiteAt', { modelPath: 'events.processed', presentation: 'On Site At', type: 'Date' }],
  ['onRouteAt', { modelPath: 'events.processed', presentation: 'On Route At', type: 'Date' }],
  ['lastEventAt', { modelPath: 'events.processed', presentation: 'Latest Event Date', type: 'Date' }],
  ['bookedBy', { modelPath: 'editedBy.email', presentation: 'Booked By', type: 'String' }],
  ['appointmentDate', { modelPath: 'slot.day', presentation: 'Booking Date', type: 'Date' }],
  ['additionalReference', { modelPath: 'customer.additionalReference', presentation: 'Additional Reference', type: 'String' }],
  ['partnerDescription', { modelPath: 'customer.partnerDescription', presentation: 'Partner Description', type: 'String' }],
  ['bookingReference', { modelPath: 'bookingReference', presentation: 'Booking Reference', type: 'String' }],
  ['engineer', { modelPath: 'engineer.name', presentation: 'Assigned Engineer Name', type: 'String' }],
  ['outwardCode', { modelPath: 'outwardCode', presentation: 'Post Code', type: 'String' }],
  ['appointmentType', { modelPath: 'appointmentType.code', presentation: 'Appointment Type', type: 'Enum' }],
  ['jobType', { modelPath: 'jobType.code', presentation: 'Job Type', type: 'Enum' }],
  ['slot', { modelPath: 'slot.timeslot.name', presentation: 'Time Slot', type: 'Enum' }],
  ['patch', { modelPath: 'slot.patch.code', presentation: 'Patch', type: 'String' }],
  ['supplier', { modelPath: 'slot.supplier.name', presentation: 'Supplier', type: 'String' }],
  ['region', { modelPath: 'slot.region.code', presentation: 'Region', type: 'String' }],
  ['status', { modelPath: 'status', presentation: 'Booking Status', type: 'Enum' }],
  ['priority', { modelPath: 'priority', presentation: 'Priority', type: 'Enum' }],
  ['priority', { modelPath: 'priority', presentation: 'Priority', type: 'Enum' }],
  ['accountNumber', { modelPath: 'customer.accountNumber', presentation: 'Account Number', type: 'String' }],
  ['customerNumber', { modelPath: 'customer.customerNumber', presentation: 'Customer Number', type: 'String' }],
  ['accountType', { modelPath: 'customer.accountType', presentation: 'Account Type', type: 'Enum' }],
  ['fuelType', { modelPath: 'jobType.fuelType', presentation: 'Fuel Type', type: 'Enum' }],
  ['mpan', { modelPath: 'meterDetails.electric.mpan', presentation: 'MPAN', type: 'String' }],
  ['mprn', { modelPath: 'meterDetails.gas.mprn', presentation: 'MPRN', type: 'String' }],
  [
    'meterSerialNumberElectric',
    {
      modelPath: 'meterDetails.electric.meterSerialNumber',
      presentation: 'Meter Serial Number Electric',
      type: 'String',
    },
  ],
  [
    'meterSerialNumberGas',
    {
      modelPath: 'meterDetails.gas.meterSerialNumber',
      presentation: 'Meter Serial Number Gas',
      type: 'String',
    },
  ],
  [
    'customerVulnerabilities',
    {
      modelPath: 'customer.vulnerabilities',
      presentation: 'Customer Vulnerabilities',
      type: 'String',
    },
  ],
  [
    'is_workflow_email_sending_enabled',
    {
      modelPath: 'events.processed',
      presentation: 'Is workflow Email Sending Enabled',
      type: 'String',
    },
  ],
  [
    'is_workflow_sms_sending_enabled',
    {
      modelPath: 'events.processed',
      presentation: 'Is workflow SMS Sending Enabled',
      type: 'String',
    },
  ],
  [
    'customer_confirmed',
    {
      modelPath: 'events.processed',
      presentation: 'Has customer confirmed',
      type: 'String',
    },
  ],
  [
    'is_workflow_white_mail_sending_enabled',
    {
      modelPath: 'events.processed',
      presentation: 'Is workflow WhiteMail Sending Enabled',
      type: 'String',
    },
  ],
  [
    'supply_start_date',
    {
      modelPath: 'customer.supplyStartDate',
      presentation: 'Supply Start Date',
      type: 'Date',
    },
  ],
  [
    'bookedByUserGroup',
    {
      modelPath: 'events.processed',
      presentation: 'Booked By User Group',
      type: 'String',
    },
  ],
  [
    'isScheduled',
    {
      modelPath: 'events.processed',
      presentation: 'Is Scheduled',
      type: 'String',
    },
  ],
  [
    'preferredContact',
    {
      modelPath: 'contact.preferredContact',
      presentation: 'Preferred Contact',
      type: 'String',
    },
  ],
  [
    'last_comms_sent',
    {
      modelPath: 'events.processed',
      presentation: 'Last Comms Sent',
      type: 'String',
    },
  ],
  [
    'comms_history',
    {
      modelPath: 'events.processed',
      presentation: 'Comms History',
      type: 'String',
    },
  ],
]);

enum AutomationFrequencyEnum {
  ONE_OFF = 'ONE_OFF',
  ABSOLUTE = 'ABSOLUTE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

enum MatchRelativeRangeEnum {
  RELATIVE_DAYS = 'RELATIVE_DAYS',
  SPECIFIC_RELATIVE_DAY = 'SPECIFIC_RELATIVE_DAY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  NEXT_WEEK = 'NEXT_WEEK',
  NEXT_MONTH = 'NEXT_MONTH',
  PREVIOUS_WEEK = 'PREVIOUS_WEEK',
  PREVIOUS_MONTH = 'PREVIOUS_MONTH',
}

interface Automation {
  id?: string;
  report?: string;
  frequency?: any[];
  oneOff?: Date;
}

enum IncDecDaysRange {
  DAYS_AGO = 'DAYS_AGO',
  DAYS_FROM_NOW = 'DAYS_FROM_NOW',
}

class AutomationModel {
  constructor(
    public id: string,
    public report: string,
    public frequency: AutomationFrequencyEnum,
    public enabled: boolean,
  ) {}
}

// New Operators
enum AllFilterOperatorsEnum {
  EQUAL = 'equal',
  NOT_EQUAL = 'notequal',
  IS_SET = 'isset',
  IS_NOT_SET = 'isnotset',
  BETWEEN = 'between',
  GT = 'greaterthan',
  GTE = 'greaterthanequal',
  LT = 'lessthan',
  LTE = 'lessthanequal',
  TOMORROW = 'tomorrow',
  YESTERDAY = 'yesterday',
  TODAY = 'today',
  IN_PAST = 'inpast',
  IN_FUTURE = 'infuture',
  CONTAINS = 'contains',
  // STARTS_WITH = 'startswith',
  // ENDS_WITH = 'endswith',
  TRUE = 'true',
  FALSE = 'false',
}
const dateOperators = [
  { value: 'equal', key: 'Equal' },
  { value: 'notequal', key: 'Not Equal' },
  { value: 'isset', key: 'Is Set' },
  { value: 'isnotset', key: 'Is Not Set' },
  { value: 'between', key: 'Between' },
  { value: 'greaterthan', key: 'Greater than' },
  { value: 'greaterthanequal', key: 'Greater than or Equal' },
  { value: 'lessthan', key: 'Less than' },
  { value: 'lessthanequal', key: 'Less than or Equal' },
  { value: 'tomorrow', key: 'Tomorrow', atomic: true },
  { value: 'yesterday', key: 'Yesterday', atomic: true },
  { value: 'today', key: 'Today', atomic: true },
  { value: 'inpast', key: 'In past', atomic: true },
  { value: 'infuture', key: 'In Future', atomic: true },
];
const standardOperators = [
  { value: 'equal', key: 'Equal' },
  { value: 'notequal', key: 'Not Equal' },
  { value: 'contains', key: 'Contains' },
  { value: 'isset', key: 'Is Set' },
  { value: 'isnotset', key: 'Is Not Set' },
];
const financialOperators = [
  { value: 'equal', key: 'Equal' },
  { value: 'notequal', key: 'Not Equal' },
  { value: 'greaterthanequal', key: 'Greater than or Equal' },
  { value: 'lessthanequal', key: 'Less than or Equal' },
  { value: 'isset', key: 'Is Set' },
  { value: 'isnotset', key: 'Is Not Set' },
];
const booleanOperators = [
  { value: 'true', key: 'True', atomic: true },
  { value: 'false', key: 'False', atomic: true },
];

const booleanYesNoOperators = [
  { value: 'true', key: 'Yes', atomic: true },
  { value: 'false', key: 'No', atomic: true },
];

const setOperators = [
  { value: 'isnotset', key: 'Is Not Set' },
  { value: 'isset', key: 'Is Set' },
];

const queryBuilderAppointmentTypeValues = [
  'SITE_SURVEY',
  'WARRANT',
  'SCHEDULED_EXCHANGE',
  'EMERGENCY_EXCHANGE',
  'DEEMED_EXCHANGE',
  'DEEMED_RECOMMISSION',
  'EMERGENCY_NEW_CONNECTION',
  'RECOMMISSION',
  'POWER_CYCLE',
  'NEW_CONNECTION',
  'METER_REMOVAL',
  'EMERGENCY_INVESTIGATION',
  'SCHEDULED_INVESTIGATION',
  'SCHEDULED_EXCHANGE_SMETS_2',
];

const queryBuilderJobTypeValues = [
  'ELECTRIC',
  'GAS',
  'DUAL_FUEL',
  'TRADITIONAL_GAS',
  'TRADITIONAL_ELECTRIC',
  'ELECTRIC_SMETS_2',
  'GAS_SMETS_2',
  'DUAL_FUEL_SMETS_2',
  'SITE_INVESTIGATION',
  'GIST',
  'SCHEDULED_POWER_CYCLE',
  'DEEMED_POWER_CYCLE',
];

const queryBuilderVulnerabilitiesTypeValues = [
  'Hearing Impaired',
  'Visually Impaired',
  'Elderly/Infant',
  'Learning Difficulties/Disabled',
  'Electrical Medical Equipment',
  'Foreign Language Speaker',
  'Restricted Movement',
  'Serious Illness',
];

const queryBuilderDccValues = ['USMART'];

const bookingMethodValues = ['MANUAL', 'API', 'BULK', 'CUSTOMER_PORTAL'];

const queryBuilderFuelTypeValues = ['GAS', 'ELECTRIC', 'DUAL', 'NONE'];

const queryBuilderAppointmentStatusValues = ['COMPLETED', 'STARTED', 'ON_SITE', 'ON_ROUTE', 'CANCELLED', 'ABORTED', 'BOOKED', 'PAUSED'];

const queryBuilderAppointmentPriorityValues = ['P1', 'P2', 'P3', 'P4'];

const queryBuilderPreferredContactValues = ['email', 'sms'];

const queryBuilderAccountTypeValues = ['credit', 'prepay'];

const queryBuilderDataConsentValues = ['Half Hourly', 'Daily', 'Monthly', 'None'];

const queryBuilderCancellationReasonValues = ['CUSTOMER_CANCELLATION', 'INSTALLER_CANCELLATION', 'SUPPLIER_CANCELLATION'];

const jobTypeChangeReasonValues = ['Customer', 'Engineer Issue', 'Meter Issue', 'No Access', 'Not Qualified', 'Supplier Issue'];

const queryBuilderJourneyCommsEmailStatusValues = ['BOUNCE', 'CLICK', 'COMPLAINT', 'OPEN', 'DELIVERY', 'SEND', 'REJECT', 'RENDERING_FAILURE', 'SEND', 'FAIL'];

const eventTypeValues = ['JOURNEY', 'BOOKING', 'TEXT', 'TECH'];

const capacityQueryBuilderFields: QueryBuilderField[] = [
  {
    label: 'Patch',
    field: 'patch.code',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Supplier',
    field: 'supplier.name',
    parent: 'Appointment',
    type: 'string',
    operators: enumOperators,
    values: [],
  },
  {
    label: 'Region',
    field: 'region.code',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
    disabled: true,
  },
  {
    label: 'Time Slot',
    field: 'timeslot.name',
    parent: 'Appointment',
    type: 'string',
    operators: enumOperators,
    values: [],
  },
];

const queryBuilderFields: QueryBuilderField[] = [
  {
    label: 'Job Type Change',
    field: 'inFlightJobTypeChange',
    parent: 'Appointment',
    type: 'boolean',
    operators: booleanOperators,
  },
  // Debt
  {
    label: 'Debt Current Value',
    field: 'debt.currentDebtValue',
    parent: 'Debt',
    type: 'financial',
    operators: financialOperators,
  },
  {
    label: 'Date Of Bill',
    field: 'debt.dateOfBill',
    parent: 'Debt',
    type: 'date',
    operators: dateOperators,
  },
  {
    label: 'Date of Red Reminder',
    field: 'debt.dateOfRedReminder',
    parent: 'Debt',
    type: 'date',
    operators: dateOperators,
  },
  {
    label: 'Min Debt Accepted By Client',
    field: 'debt.minimumDebtAcceptedByClient',
    parent: 'Debt',
    type: 'financial',
    operators: financialOperators,
  },
  {
    label: 'Referral For Debt Collection Activity',
    field: 'debt.referralForDebtCollectionActivity',
    parent: 'Debt',
    type: 'date',
    operators: dateOperators,
  },
  {
    label: 'Pre Disconnection Visit',
    field: 'debt.preDisconnectionVisit',
    parent: 'Debt',
    type: 'date',
    operators: dateOperators,
  },
  {
    label: 'Intended Date For Execution',
    field: 'debt.intendedDateForExecution',
    parent: 'Debt',
    type: 'date',
    operators: dateOperators,
  },
  {
    label: 'Current Debt Journey',
    field: 'debt.debtJourneyActiveStep',
    parent: 'Debt',
    type: 'string',
    operators: enumOperators,
    values: debtJourneySteps.sort(),
  },
  {
    label: 'Current Debt Journey Next Action Date',
    field: 'debt.debtJourneyDate',
    parent: 'Debt',
    type: 'date',
    operators: dateOperators,
  },
  {
    label: 'Job Type Change Reason',
    field: 'inFlightJobTypeChangeReason',
    parent: 'Appointment',
    type: 'string',
    operators: enumOperators,
    values: jobTypeChangeReasonValues.sort(),
  },
  {
    label: 'Rescheduled From TimeSlot',
    field: 'rescheduledFromTimeSlot',
    parent: 'Appointment',
    type: 'string',
    operators: enumOperators,
    values: [],
  },
  {
    label: 'HSE',
    field: 'hse',
    parent: 'Appointment',
    type: 'boolean',
    operators: booleanOperators,
  },
  {
    label: 'Vulnerable',
    field: 'vulnerable',
    parent: 'Appointment',
    type: 'boolean',
    operators: booleanOperators,
  },
  {
    label: 'Cloned',
    field: 'cloned',
    parent: 'Appointment',
    type: 'boolean',
    operators: booleanOperators,
  },
  {
    label: 'Dcc',
    field: 'dcc',
    parent: 'Appointment',
    type: 'string',
    operators: enumOperators,
    values: queryBuilderDccValues.sort(),
  },
  {
    label: 'Booking Method',
    field: 'bookingMethod',
    parent: 'Appointment',
    type: 'string',
    operators: enumOperators,
    values: bookingMethodValues.sort(),
  },
  {
    label: 'Patch',
    field: 'patch.code',
    parent: 'Capacity',
    type: 'string',
    operators: enumOperators,
    values: [],
  },
  {
    label: 'Booked On',
    field: 'createdOn',
    parent: 'Appointment',
    type: 'date',
    operators: dateOperators,
  },
  {
    label: 'Last Edited By',
    field: 'lastEditedBy',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Appointment Type',
    field: 'appointmentType.code',
    parent: 'Appointment',
    type: 'string',
    operators: enumOperators,
    values: [],
  },
  {
    label: 'Cancelled Reason',
    field: 'events_processed_cancelledReason',
    parent: 'Status',
    type: 'string',
    operators: enumOperators,
    values: queryBuilderCancellationReasonValues.sort(),
  },
  {
    label: 'Cancelled Subreason',
    field: 'events_processed_cancelledSubReason',
    parent: 'Status',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Cancelled Code',
    field: 'events_processed_cancelledCode',
    parent: 'Status',
    type: 'string',
    operators: enumOperators,
    values: queryBuilderCancellationReasonValues.sort(),
  },
  {
    label: 'Cancelled Comment',
    field: 'events_processed_cancelledComment',
    parent: 'Status',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Last Event Date',
    field: 'events_processed_lastEventDate',
    parent: 'Status',
    type: 'date',
    operators: dateOperators,
  },
  {
    label: 'Last Event Type',
    field: 'events_processed_lastEventType',
    parent: 'Status',
    type: 'string',
    operators: enumOperators,
    values: eventTypeValues.sort(),
  },
  {
    label: 'Last Event User Name',
    field: 'events_processed_lastEventUserName',
    parent: 'Status',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Surveys Received',
    field: 'surveysReceived',
    parent: 'Appointment',
    type: 'boolean',
    operators: setOperators,
  },
  {
    label: 'Is VIP',
    field: 'customer.vip',
    parent: 'Appointment',
    type: 'boolean',
    operators: setOperators,
  },
  {
    label: 'Non Chargeable',
    field: 'nonChargeable',
    parent: 'Appointment',
    type: 'boolean',
    operators: booleanOperators,
  },
  {
    label: 'Is Flexible',
    field: 'customer.flexible',
    parent: 'Appointment',
    type: 'boolean',
    operators: setOperators,
  },
  {
    label: 'Has Journey Email Bounce',
    field: 'journeyCommsEmailBounce',
    parent: 'Journey',
    type: 'boolean',
    operators: booleanOperators,
  },
  {
    label: 'Has Journey Email Complaint',
    field: 'journeyCommsEmailComplaint',
    parent: 'Journey',
    type: 'boolean',
    operators: booleanOperators,
  },
  {
    label: 'Has Journey Email Fail',
    field: 'journeyCommsEmailFail',
    parent: 'Journey',
    type: 'boolean',
    operators: booleanOperators,
  },
  {
    label: 'Latest Journey Email Status Change Date',
    field: 'journeyCommsEmailStatusChangeDate',
    parent: 'Journey',
    type: 'date',
    operators: dateOperators,
  },
  {
    label: 'Latest Journey Email Status',
    field: 'journeyCommsEmailStatus',
    type: 'string',
    parent: 'Journey',
    operators: enumOperators,
    values: queryBuilderJourneyCommsEmailStatusValues.sort(),
  },
  {
    label: 'Booking Reference',
    field: 'bookingReference',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Account Number',
    field: 'customer.accountNumber',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Customer Number',
    field: 'customer.customerNumber',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Customer Additional Reference',
    field: 'customer.additionalReference',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Customer Partner Description',
    field: 'customer.partnerDescription',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Customer First Name',
    field: 'customer.firstName',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Customer Surname',
    field: 'customer.surname',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Customer Password',
    field: 'site.customerPassword',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Customer Title',
    field: 'customer.title',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Customer Vulnerabilities',
    field: 'customer.vulnerabilities',
    parent: 'Appointment',
    type: 'string',
    operators: enumOperators,
    values: queryBuilderVulnerabilitiesTypeValues.sort(),
  },
  {
    label: 'Account Type',
    field: 'customer.accountType',
    parent: 'Appointment',
    type: 'string',
    operators: enumOperators,
    values: queryBuilderAccountTypeValues.sort(),
  },
  {
    label: 'Engineer',
    field: 'engineer.name',
    parent: 'Schedule',
    type: 'stringSearch',
    operators: enumSearchOperators,
    values: [],
  },
  {
    label: 'Customer Confirmed',
    field: 'customerConfirmation',
    parent: 'Journey',
    type: 'boolean',
    operators: booleanYesNoOperators,
  },
  {
    label: 'Assigner',
    field: 'lastAssigner',
    parent: 'Appointment',
    type: 'stringSearch',
    operators: enumSearchOperators,
    values: [],
  },
  {
    label: 'MPAN',
    field: 'meterDetails.electric.mpan',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Comments',
    field: 'comments.comment',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'SSC',
    field: 'meterDetails.electric.sscCode',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'UK Profile Class',
    field: 'meterDetails.electric.profileClass',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'MPRN',
    field: 'meterDetails.gas.mprn',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Job Type',
    field: 'jobType.code',
    parent: 'Appointment',
    type: 'string',
    operators: enumOperators,
    values: queryBuilderJobTypeValues.sort(),
  },
  {
    label: 'Fuel Type',
    field: 'jobType.fuelType',
    parent: 'Appointment',
    type: 'string',
    operators: enumOperators,
    values: queryBuilderFuelTypeValues.sort(),
  },
  {
    label: 'Post Code',
    field: 'outwardCode',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Time Slot',
    field: 'timeslot.name',
    parent: 'Appointment',
    type: 'string',
    operators: enumOperators,
    values: [],
  },
  {
    label: 'Booked By name',
    field: 'editedBy.name',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Supplier',
    field: 'supplier.name',
    parent: 'Supplier',
    type: 'string',
    operators: enumOperators,
    values: [],
  },
  {
    label: 'Region',
    field: 'region.code',
    parent: 'Capacity',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Booking Status',
    field: 'status',
    parent: 'Status',
    type: 'string',
    operators: enumOperators,
    values: queryBuilderAppointmentStatusValues.sort(),
  },
  {
    label: 'Priority',
    field: 'priority',
    parent: 'Appointment',
    type: 'string',
    operators: enumOperators,
    values: queryBuilderAppointmentPriorityValues.sort(),
  },
  {
    label: 'Wan Status',
    field: 'wan.status',
    parent: 'Status',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Data Consent',
    field: 'site.dataConsent',
    parent: 'Appointment',
    type: 'string',
    operators: enumOperators,
    values: queryBuilderDataConsentValues.sort(),
  },
  {
    label: 'Address Line 1',
    field: 'site.address1',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Address Line 2',
    field: 'site.address2',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Address Line 3',
    field: 'site.address3',
    parent: 'Appointment',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Existing Electric MSN',
    field: 'meterDetails.electric.meterSerialNumber',
    parent: 'Status',
    type: 'string',
    operators: standardOperators,
    disabled: true,
  },
  {
    label: 'Existing Gas MSN',
    field: 'meterDetails.gas.meterSerialNumber',
    parent: 'Status',
    type: 'string',
    operators: standardOperators,
    disabled: true,
  },
  {
    label: 'WAN Additional Information',
    field: 'wan.additionalInformation',
    parent: 'Wan',
    type: 'string',
    operators: standardOperators,
    disabled: true,
  },
  {
    label: 'WAN Anticipated Coverage Date',
    field: 'wan.anticipatedCoverageDate',
    parent: 'Wan',
    type: 'date',
    operators: standardOperators,
  },
  {
    label: 'WAN Connectivity Likelihood',
    field: 'wan.connectivityLikelihood',
    parent: 'Wan',
    type: 'string',
    operators: standardOperators,
    disabled: true,
  },
  {
    label: 'WAN Coverage Availability',
    field: 'wan.coverageAvailability',
    parent: 'Wan',
    type: 'string',
    operators: standardOperators,
    disabled: true,
  },
  {
    label: 'WAN CSP Region',
    field: 'wan.cspRegion',
    parent: 'Wan',
    type: 'string',
    operators: standardOperators,
    disabled: true,
  },
  {
    label: 'WAN Status Checked At',
    field: 'wan.updatedOn',
    parent: 'Wan',
    type: 'date',
    operators: dateOperators,
    disabled: true,
  },
  {
    label: 'WAN Technology',
    field: 'wan.wanTechnology',
    parent: 'Wan',
    type: 'string',
    operators: standardOperators,
  },
  {
    label: 'Over Booked',
    field: 'overBooked',
    parent: 'Appointment',
    type: 'boolean',
    operators: booleanYesNoOperators,
  },
  {
    label: 'Three Phase',
    field: 'meterDetails.electric.threePhase',
    parent: 'Appointment',
    type: 'boolean',
    operators: booleanOperators,
  },
  {
    label: 'Medium Pressure',
    field: 'meterDetails.gas.mediumPressure',
    parent: 'Appointment',
    type: 'boolean',
    operators: booleanOperators,
  },
  {
    label: 'Economy 7',
    field: 'meterDetails.electric.economySeven',
    parent: 'Appointment',
    type: 'boolean',
    operators: booleanOperators,
  },
  {
    label: 'Current Transformer',
    field: 'meterDetails.electric.currentTransformer',
    parent: 'Appointment',
    type: 'boolean',
    operators: booleanOperators,
  },
  { label: 'Supply Start Date', field: 'customer.supplyStartDate', type: 'date', operators: dateOperators, parent: 'Appointment' },
  { label: 'Booked by User Group', field: 'bookedByUserGroup', type: 'string', operators: standardOperators, parent: 'Appointment' },
  { label: 'Contact SMS', field: 'contact.contactSms', type: 'string', operators: standardOperators, parent: 'Appointment' },
  { label: 'Contact Email Address', field: 'contact.contactEmail', type: 'string', operators: standardOperators, parent: 'Appointment' },
  { label: 'Contact Name', field: 'contact.firstName', type: 'string', operators: standardOperators, parent: 'Appointment' },
  { label: 'Contact Telephone', field: 'contact.contactTelephone', type: 'string', operators: standardOperators, parent: 'Appointment' },
  { label: 'Is Scheduled', field: 'isScheduled', type: 'boolean', operators: booleanOperators, parent: 'Scheduled' },
  { label: 'Is Rescheduled', field: 'isRescheduled', type: 'boolean', operators: booleanOperators, parent: 'Scheduled' },
  {
    label: 'Preferred Contact',
    field: 'contact.preferredContact',
    type: 'string',
    operators: enumOperators,
    values: queryBuilderPreferredContactValues,
    parent: 'Appointment',
  },
  { label: 'Multi Engineer Job', field: 'multiEngineerJob', type: 'boolean', operators: booleanOperators, parent: 'Appointment' },
];

interface QueryBuilderRule {
  field: string;
  label: string;
  operator: string;
  type: string;
  value: string;
}

export {
  Field,
  fields,
  capacityFields,
  supportedCapacityFieldsMap,
  supportedReportFieldsMap,
  Filter,
  Report,
  ReportModel,
  Frequency,
  databaseFiltersStatus,
  databaseFiltersJobTypes,
  databaseFiltersAppointmentTypes,
  databaseFiltersStrings,
  databaseFiltersNumbers,
  databaseFiltersAccountTypes,
  ActiveFilter,
  Automation,
  AutomationModel,
  AutomationFrequencyEnum,
  DialogData,
  NewReportData,
  AutomationDialogData,
  ReportUpdate,
  ReportType,
  MatchRelativeRangeEnum,
  IncDecDaysRange,
  capacityQueryBuilderFields,
  queryBuilderFields,
  queryBuilderCancellationReasonValues,
  queryBuilderDataConsentValues,
  queryBuilderAccountTypeValues,
  queryBuilderAppointmentStatusValues,
  queryBuilderFuelTypeValues,
  queryBuilderJobTypeValues,
  queryBuilderAppointmentTypeValues,
  QueryBuilderField,
  FiltersQueryBuilder,
  QueryBuilderRule,
  SurveyKey,
  standardOperators,
  AllFilterOperatorsEnum,
};
