import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { tokenGetter } from '@nexus/auth/auth.service';
import { EnvironmentService, EnvironmentVariables } from './services/environment.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private environmentService: EnvironmentService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = tokenGetter();
    const isApiUrl = request.url.startsWith(this.environmentService.get(EnvironmentVariables.API_URL));
    let cloneRequest = request;
    if (token && isApiUrl) {
      cloneRequest = cloneRequest.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(cloneRequest);
  }
}
