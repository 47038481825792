import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EnvironmentService, EnvironmentVariables } from '@nexus/core-services/environment.service';
import { AtomicServerResponse } from '@nexus/models/api.model';
import { Tenant } from '@nexus/models/tenant.model';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TenantService {
  private apiUrl: string;
  public tenantData: any;
  // TODO the next 2 subjects must be private
  public tenantData$ = new ReplaySubject<Tenant>(null);
  public tenantDataSet$ = new BehaviorSubject<boolean>(false);
  public tenantDataInfo$: Observable<any> = this.tenantData$.asObservable();
  constructor(
    private httpClient: HttpClient,
    private environmentService: EnvironmentService,
  ) {
    this.apiUrl = `${environmentService.get(EnvironmentVariables.API_URL)}/v1/api`;
  }

  public setTenantData(): void {
    if (!this.isTenantDataSet()) {
      this.getTenantConfigs().subscribe((tenantData) => {
        this.tenantData = tenantData;
        this.tenantData$.next(tenantData);
        this.tenantDataSet$.next(true);
      });
    }
  }

  getTenantConfigs(): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/tenant`;
    return this.defaultGet(completeApiUrl);
  }

  defaultGet(completeApiUrl: string): Observable<any> {
    return this.httpClient.get<AtomicServerResponse>(completeApiUrl).pipe(
      map((response) => {
        return response.result;
      }),
    );
  }

  public isTenantDataSet(): boolean {
    return this.tenantDataSet$.value;
  }

  public removeTenantData(): void {
    this.tenantData$.next(null);
    this.tenantDataSet$.next(false);
  }

  public getObservableTenantData(): Observable<any> {
    return this.tenantData$;
  }

  public getTenantData() {
    return this.tenantData;
  }
}
