import { Subcontractor } from './subcontractor.model';

interface Qualification {
  category: string;
  name: string;
  description: string;
  sqlId: number;
  createdOn?: Date;
  _id?: string;
}

interface UserQualification {
  qualification: Qualification;
  expiresAt: string;
}

interface License {
  sqlId: string;
  typeId: number;
  typeName: string;
  expiresAt: string;
  createdOn: Date;
  _id?: string;
}
interface UserLicense {
  license: License;
  licenseNumber: number;
  expiresAt: string;
  createdOn: Date;
  _id?: string;
}

/**
 * @Deprecated Use EngineerDoc instead
 * */
class EngineerModel {
  constructor(
    public name?: string,
    public email?: string,
    public patches?: Array<string>,
    public id?: string,
    public appointmentsCount?: number,
    public homePatch?: string,
    public groups?: Array<any>,
    public subcontractor?: Subcontractor,
    public qualifications?: Array<UserQualification>,
    public licenses?: Array<UserLicense>,
    public active?: boolean
  ) {}
}
interface EngineerDoc {
  name?: string;
  email?: string;
  active?: boolean;
  patches?: string[];
  groups?: any[];
  id?: string;
  appointmentsCount?: number;
  homePatch?: string;
  qualifications?: UserQualification[];
  licenses?: UserLicense[];
  subcontractor?: Subcontractor;
}

export { EngineerModel, EngineerDoc, Qualification, License, UserLicense, UserQualification };
