import { AppointmentModel } from '@nexus/models/appointment.model';
import { Patch } from '@nexus/models/patch.model';

interface SlotAppointment {
  floor?: number;
  ceiling?: number;
  colSpan: number;
  outwardCode: string;
  patch: Patch;
  duration: number;
  bookingReference: string;
  timeSlot: string;
  status: string;
  engineer: string;
  patchId: string;
  events: any[];
  appointmentType: string;
  jobType: string;
  id: string;
  isJourney?: boolean;
}

interface todayConfigInterface {
  _id: string;
  rowSpan: number;
  appointmentType: any;
  jobType: any;
  consumptionUnits: number;
}

interface SlotGap {
  colSpan: number;
  duration: number;
  timeSlot?: string;
  floor?: number;
  ceiling?: number;
  isJourney?: boolean;
}

interface Slot {
  type: SlotType;
  data: SlotAppointment | SlotGap;
}

interface SlotGap {
  colSpan: number;
  duration: number;
}

enum SlotType {
  gap = 'GAP',
  appointment = 'APPOINTMENT',
}

enum DayJobGroupingStrategy {
  SEQUENCE = 'SEQUENCE',
  GAPPED = 'GAPPED',
}

interface Slot {
  type: SlotType;
  data: SlotAppointment | SlotGap;
}

interface DayJob {
  engineer: any;
  appointments: AppointmentModel[];
}

interface EngineerChangePayload {
  appointmentId: string;
  engineerId: string;
}

class DayJobModel {
  constructor(
    public engineer: any,
    public appointments: AppointmentModel[],
    public totalAppointments?: number,
  ) {}
}

interface TodayFilters {
  engineer?: string;
  region?: string;
  slot?: string;
  postcode?: string;
  accountNumber?: string;
  customerNumber?: string;
  emergency?: boolean;
  threePhase?: boolean;
  mediumPressure?: boolean;
  patch: string;
  jobType?: string;
  appointmentType?: string;
}

export { DayJobModel, SlotAppointment, SlotGap, SlotType, Slot, DayJob, DayJobGroupingStrategy, TodayFilters, EngineerChangePayload, todayConfigInterface };
