import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvironmentService } from '@nexus/core-services/environment.service';
import { VersioningService } from '@nexus/core-services/versioning.service';
import { ValidationService } from '@nexus/core-services/validation.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [EnvironmentService, VersioningService, ValidationService],
})
export class CoreModule {
  constructor(private versioningService: VersioningService) {}
}
