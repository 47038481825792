export const environment = {
  environment: 'STAGING',
  production: true,
  tenant: 'voltari',
  hmr: false,
  apiUrl: 'https://voltari.staging.nexus-api.reachwm.io',
  hubAuthUrl: 'https://staging.hub.reachwm.io/login?redirect=https://voltari.staging.nexus.reachwm.io',
  hubUrl: 'https://staging.hub.reachwm.io',
  directAuthUrl: 'https://staging.hub.reachwm.io/api/authenticate',
  directAuthRefreshUrl: 'https://staging.hub.reachwm.io/api/token',
  capacityTemplate: 'https://nexus-public.s3.eu-west-2.amazonaws.com/capacity-template-nexus.xlsx',
  capacityRotaTemplate: 'https://nexus-public.s3.eu-west-2.amazonaws.com/rota-template.csv',
  prebookTemplate: 'https://nexus-public.s3.eu-west-2.amazonaws.com/prebook-template.csv',
};
