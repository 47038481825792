class JwtUserModel {
  public tokenExpiresAt;
  public tokenCreatedAt;
  public cannotBeUsedBefore;
  constructor(
    public email: string,
    tokenExpiresAt: number,
    tokenCreatedAt: number,
    public issuer: string,
    public claim: string,
    public name: string,
    cannotBeUsedBefore: number,
    public roles: any[],
    public groups: any[],
    public subscriber: number,
    public tenant: any,
    public superUser: boolean,
    public hidden: boolean,
    public active: boolean,
    public id: string,
    public subcontractor: boolean,
  ) {
    this.tokenExpiresAt = new Date(tokenExpiresAt * 1000);
    this.tokenCreatedAt = new Date(tokenCreatedAt * 1000);
    this.cannotBeUsedBefore = new Date(cannotBeUsedBefore * 1000);
  }
}
export { JwtUserModel };
