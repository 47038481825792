/* Enumeration Model */
class TimeSlotModel {
  constructor(
    public name: string,
    public startTime: Date,
    public endTime: Date,
    public duration: number,
    public id?: string,
  ) {}
}

interface TimeSlotConfig {
  id: string;
  slots: number;
  noOpDay?: boolean;
}

interface TimeSlot {
  name: string;
  startTime: Date;
  endTime: Date;
  duration: number;
  id?: string;
}

export { TimeSlotModel, TimeSlotConfig, TimeSlot };
