import { PatchModel } from '@nexus/models/patch.model';
import { TimeSlotModel } from '@nexus/models/timeslot.model';
import { EngineerDoc } from './engineer.model';
// interface Supplier {
//   region: any,
//   patches: any[]
// }

class SlotModel {
  constructor(
    public availableUnits: number,
    public patch: string,
    public supplier: string,
    public timeSlot: any,
    public day: Date,
    public id?: string,
    public noOpDay?: boolean,
    public appointments?: any,
    public multiDaySupportOnly?: boolean,
    public parentId?: string
  ) {}
}

interface NewSlotData {
  availableUnits: number;
  patch: string;
  supplier: string;
  timeSlot: string;
  day: Date;
}

interface AppointmentsCount {
  engineer: EngineerDoc;
  count: number;
}

class PatchSlotsGrouping {
  constructor(
    public slots: [SlotModel],
    public patch: string,
  ) {}
}
class DaySlotsGrouping {
  multiDaySupportOnly: any;
  constructor(
    public slots: [SlotModel],
    public day: string,
  ) {}
}

interface SelectedSlot {
  multiDaySupportOnly?: boolean;
  slotDay: number;
  slotType: string;
  slotCalendarDay: string;
}

interface JobFlag {
  _id: string;
  code: string;
  qualifications: string[];
  licenses: string[];
  name: string;
  id: string;
}

export { SlotModel, NewSlotData, PatchSlotsGrouping, DaySlotsGrouping, AppointmentsCount, SelectedSlot, JobFlag };
