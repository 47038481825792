import { ActivatedRouteSnapshot, DetachedRouteHandle, Route, RouteReuseStrategy } from '@angular/router';

export class CustomReuseStrategy implements RouteReuseStrategy {
  private handlers = new Map<Route, DetachedRouteHandle>();
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const key = route.data.key;
    if (key === 'today') {
      return true;
    }
    return false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.handlers.set(this.calcPath(route), handle);
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!this.handlers.get(this.calcPath(route));
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return this.handlers.get(this.calcPath(route));
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  private calcPath(route: ActivatedRouteSnapshot) {
    return route.routeConfig;
  }
}
