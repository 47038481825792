import { Injectable } from '@angular/core';

import { Subscription, combineLatest, ReplaySubject, Subject, BehaviorSubject, Observable, of } from 'rxjs';

@Injectable()
export class FormsDisablerService {
  public allFormsDisabled$ = new Subject<boolean>();

  constructor() {}

  disableAllForms(): void {
    this.allFormsDisabled$.next(true);
  }

  enableAllForms(): void {
    this.allFormsDisabled$.next(false);
  }
}
