import { Injectable } from '@angular/core';
import { Router, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvironmentService, EnvironmentVariables } from '@nexus/core-services/environment.service';
import { AuthService } from '@nexus/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class GuestGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
    private environmentService: EnvironmentService,
  ) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean | UrlTree>((resolve, reject) => {
      this.authService.loggedIn$.pipe(first()).subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          resolve(this.router.parseUrl('/platform'));
        } else {
          const hubAuthUrl = this.environmentService.get(EnvironmentVariables.HUB_AUTH_URL);
          window.open(hubAuthUrl, '_self');
        }
      });
    });
  }
  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
