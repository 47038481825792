import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { EnvironmentService, EnvironmentVariables } from '@nexus/core-services/environment.service';

@Injectable({
  providedIn: 'root',
})
export class LoggingService implements OnDestroy {
  private queue = [];
  private interval;
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient,
    private environmentService: EnvironmentService,
  ) {
    this.interval = setInterval(() => {
      this.clearQueue();
    }, 5000);
    this.apiUrl = `${this.environmentService.get(EnvironmentVariables.API_URL)}/v1/api`;
  }

  clearQueue() {
    let message = 'CLIENT:';
    for (let index = 0; index < this.length(); index++) {
      if (this.length() > index) {
        message += ` ${this.removeFromQueue()} | `;
      }
    }
    if (message === 'CLIENT:') {
      return;
    }
    const completeApiUrl = `${this.apiUrl}/logs/`;
    this.httpClient.post<any>(completeApiUrl, { message }, { observe: 'response' }).subscribe((data) => {});
  }
  addToQueue(item: string) {
    if (item.indexOf('401') > -1) {
      this.queue.unshift(item); // Inserts new elements at the start of an array
    }
  }
  removeFromQueue() {
    return this.queue.pop();
  }
  peek() {
    return this.queue[this.queue.length - 1];
  }
  length() {
    return this.queue.length;
  }
  isEmpty() {
    return this.queue.length === 0;
  }
  log(level: any, message: string, data?: any) {
    if (level === 'debug') {
      return;
    }
    let dataStringified = data;
    if (typeof data === 'object') {
      try {
        dataStringified = JSON.stringify(data);
      } catch (err) {
        dataStringified = 'Failed to stringify error';
      }
    }
    this.addToQueue(`${window.location.pathname} ${level}: ${message}, ${dataStringified ? dataStringified : ''}`);
  }
  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
