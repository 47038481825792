/**
 *
 * @deprecated Do not use this class, use the interface instead
 * @class UserModel
 */
class UserModel {
  constructor(
    public name?: string,
    public email?: string,
    public roles?: Array<any>,
    public id?: string,
    public groups?: Array<string | any>,
    public hidden?: boolean,
    public active?: boolean,
    public superUser?: boolean,
    public subcontractor?: string,
    public qualifications?: Array<string | any>,
    public licenses?: Array<string | any>,
  ) {}
}

interface User {
  name?: string;
  email?: string;
  roles?: string[];
  groups?: string[];
  id?: string;
  hidden?: boolean;
  active?: boolean;
  superUser?: boolean;
}

interface UserDoc {
  name?: string;
  email?: string;
  roles?: string[];
  groups?: string[];
  id?: string;
  _id?: string;
  appointmentsCount?: number;
  hidden?: boolean;
  active?: boolean;
  superUser?: boolean;
}

export { UserModel, UserDoc, User };
