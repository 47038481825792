import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject, ReplaySubject, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DateFilterService {
  private privateDate;

  public selectedDate$: BehaviorSubject<any> = new BehaviorSubject(new Date());

  public set date(date: any) {
    this.privateDate = date;
    this.selectedDate$.next(date);
  }

  public get date() {
    return this.privateDate;
  }

  constructor() {}
}
