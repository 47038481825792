import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { LoggingService } from '@nexus/services/logging.service';
import { NotificationService } from '@nexus/services/notification.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  public errorMsg = ``;
  private logService: LoggingService;
  public notificationService: NotificationService;

  constructor(
    private injector: Injector,
    private translocoService: TranslocoService,
  ) {
    this.logService = this.injector.get(LoggingService);
    this.notificationService = this.injector.get(NotificationService);
  }

  handleError(error) {
    this.errorMsg = this.translocoService.translate('generic.backend-500-error');
    this.logService.log('error', 'GlobalErrorHandler', error);
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      if (confirm('New version available. Load New Version?')) {
        window.location.reload();
      }
    }
    let customErrorMsg = this.errorMsg;
    if (error && error.status && error.status === 401) {
      customErrorMsg = 'Your session has expired. Please login again.';
    }
    this.notificationService.notify(customErrorMsg);
  }

  static handleErrorExtractor(error: Error) {}

  static _defaultExtractor(errorCandidate: unknown): unknown {
    let error = errorCandidate;

    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && (error as { ngOriginalError: Error }).ngOriginalError) {
      error = (error as { ngOriginalError: Error }).ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      const chunkFailedMessage = /Loading chunk [\d]+ failed/;
      if (chunkFailedMessage.test(error?.error?.message)) {
        if (confirm('New version available. Load New Version?')) {
          window.location.reload();
        }
      }

      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent && error.error.message) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Nothing was extracted, fallback to default error message.
    return null;
  }
}
