import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { GuestGuard } from './guest.guard';
import { RoleGuard } from './role.guard';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [GuestGuard, RoleGuard],
})
export class AuthModule {}
