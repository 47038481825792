import { Injectable, Injector } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NotificationService } from '@nexus/services/notification.service';
import { AutosaveService } from '@nexus/services/autosave.service';
import { EnvironmentService, EnvironmentVariables } from '@nexus/core-services/environment.service';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptorService implements HttpInterceptor {
  public static httpCommunicating$ = new ReplaySubject<boolean>();
  private logService;

  constructor(
    private injector: Injector,
    private autosaveService: AutosaveService,
    private environmentService: EnvironmentService,
    private notificationService: NotificationService,
  ) {
    this.logService = this.injector.get(LoggingService);
  }
  // Hook for All HTTP Requests
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event) => {
          this.notifyAll(event);
        },
        (event) => {
          if (event instanceof HttpErrorResponse) {
            this.autosaveService.finishSaving();
            if (event.status === 401) {
              this.notificationService.notifyi18n('generic.backend-401-error');
              localStorage.removeItem('access_token');
              setTimeout(() => {
                const hubAuthUrl = this.environmentService.get(EnvironmentVariables.HUB_AUTH_URL) + '&s=inactivity';
                window.open(hubAuthUrl, '_self');
              }, 2000);
            }
            if (event.status === 403) {
              this.logService.log('error', `Got 403`, event);
              this.notificationService.notifyi18n('generic.backend-403-error');
            }
            if (event.status === 500) {
              this.logService.log('error', `Got 500`, event);
              this.notificationService.notifyi18n('generic.backend-500-error');
            }
            if (event.status === 503) {
              this.logService.log('error', `Got 503`, event);
              this.notificationService.notifyi18n('generic.backend-503-error');
            }
            if (event.status === 402) {
              this.logService.log('error', `Got 402`, event);
              this.notificationService.notifyi18n('generic.backend-402-error');
            }
          }
        },
      ),
    );
  }

  notifyAll(event: any) {
    if (event.type === 0) {
      RequestInterceptorService.httpCommunicating$.next(true);
    }
    if (event.type === 4) {
      RequestInterceptorService.httpCommunicating$.next(false);
    }
  }

  getObservable() {
    // return this.communicating;
    return RequestInterceptorService.httpCommunicating$;
  }
}
