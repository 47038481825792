class PatchModel {
  constructor(
    public code: string,
    public name: string,
    public region: any,
    public outwardCodes: Array<string>,
    public id?: string,
    public hasUnassignedAppointments?: boolean,
  ) {}
}

interface NewPatchData {
  name: string;
  code: string;
  region?: string;
}

interface Patch {
  name: string;
  code: string;
  region: string;
  outwardCodes: Array<string>;
  id: string;
  hasUnassignedAppointments: boolean;
}

interface DialogData {
  operation: string;
  data: NewPatchData;
}

interface ToggledPatch {
  patchCode: string;
  toggled: boolean;
}

export { PatchModel, NewPatchData, DialogData, ToggledPatch, Patch };
