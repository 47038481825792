import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, interval, Subscription, Subject } from 'rxjs';
import { map, takeUntil, takeWhile } from 'rxjs/operators';

import ServerVersion from '../../../assets/version.json';

@Injectable()
export class VersioningService implements OnDestroy {
  private source$: Observable<number>;
  private currentVersion: string;
  protected subscriptions = new Subscription();
  private serverVersion;
  private userIgnoredWarning = new Subject<boolean>();
  private userIgnoredWarning$: Observable<boolean> = this.userIgnoredWarning.asObservable();

  constructor(private httpClient: HttpClient) {
    this.currentVersion = ServerVersion.version;
    this.source$ = interval(300000); // 5min
    this.startCheckCycle();
  }
  startCheckCycle() {
    this.subscriptions.add(
      this.source$.pipe(takeUntil(this.userIgnoredWarning$)).subscribe((val) => {
        this.subscriptions.add(
          this.loadVersionFromServer().subscribe(
            (data) => {
              this.serverVersion = data.version;
              if (this.serverVersion !== this.currentVersion) {
                if (confirm(`There is a new version of the application ${this.serverVersion}. Do you want to update now?`)) {
                  location.reload();
                } else {
                  this.userIgnoredWarning.next(false);
                }
              }
            },
            // (err) => {

            // }
          ),
        );
      }),
    );
  }
  loadVersionFromServer() {
    const completeApiUrl = 'assets/version.json';
    return this.httpClient.get<{ version: string }>(completeApiUrl);
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
