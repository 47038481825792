import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private scrollRequesterSubject = new Subject<string>();
  public scrollRequester$ = this.scrollRequesterSubject.asObservable();

  constructor() {}

  requestScroll(type: string) {
    this.scrollRequesterSubject.next(type);
  }
}
