export const environment = {
  environment: 'PRODUCTION',
  production: true,
  tenant: 'evs',
  hmr: false,
  apiUrl: 'https://evs.nexus-api.reachwm.io',
  hubAuthUrl: 'https://hub.reachwm.io/login?redirect=https://evs.nexus.reachwm.io',
  hubUrl: 'https://hub.reachwm.io',
  directAuthUrl: 'https://hub.reachwm.io/api/authenticate',
  directAuthRefreshUrl: 'https://hub.reachwm.io/api/token',
  capacityTemplate: 'https://nexus-public.s3.eu-west-2.amazonaws.com/capacity-template-nexus.xlsx',
  capacityRotaTemplate: 'https://nexus-public.s3.eu-west-2.amazonaws.com/rota-template.csv',
  prebookTemplate: 'https://nexus-public.s3.eu-west-2.amazonaws.com/prebook-template.csv',
  getAddressApiKey: '9icqmcZPf0SSD8krbtnutw14124',
};
