import { Injectable, Output, Input, EventEmitter, Directive } from '@angular/core';

@Directive()
@Injectable({
  providedIn: 'root',
})
export class AutosaveService {
  private autoSavingState = false;
  @Output() autoSaving = new EventEmitter(); // event emitter

  constructor() {}

  startSaving() {
    this.autoSaving.emit(true);
    this.autoSavingState = true;
  }
  finishSaving() {
    this.autoSaving.emit(false);
    this.autoSavingState = false;
  }
  getObservable() {
    return this.autoSaving;
  }
}
