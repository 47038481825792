<div mat-dialog-content>
  <article class="mw7 center ph3 ph5-ns tc br2 pv0 mb2">
    <!-- <h1 class="fw6 f3 f2-ns lh-title mt0 mb3">This is a tagline. For x.</h1> -->
    <h2 class="fw2 f4 lh-copy mt0 mb3">
      {{ data.message }}
    </h2>
    <div class="ph0 pa2">
      <a class="f6 link dim ba ph3 pv2 mb2 dib nexus-green" data-cy="override-capacity-btn" href="{{ data.link }}"> Go to Login </a>
    </div>
  </article>
</div>
